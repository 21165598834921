import service from "@/api/service";
import { GetResponse } from "@/api/shop-stamp/response";
import { SearchRequest } from "@/api/shop-stamp/request";
import { SearchResponse } from "@/api/shop-stamp/response";

/**
 * スタンプ店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-stamp-shop", searchRequest);
  return response.data as SearchResponse;
}

/**
 * スタンプカード店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-stamp-shop");
  return response.data as GetResponse;
}
